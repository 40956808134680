<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
    >{{message}}</vs-alert>
    <br />
    <form>
      <label for="email">Email</label>
      <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        id="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        v-model="user.email"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>

      <div class="passwordField">
        <label for="password">Password</label>

          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required'"
            :type="passwordFieldType"
            name="password"
            id="password"
            icon-no-border
            icon-pack="feather"
            :icon="passwordShowIcon"
            v-model="user.password"
            class="passwordInput"
            @click.native="switchPasswordVisibility($event)"
            @keyup.enter="loginAdmin"
          />
        <span class="text-danger text-sm">{{ errors.first('password') }}</span>
      </div>
    </form>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox>
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>

    <div class="flex flex-wrap justify-between mb-3">
      <vs-button :disabled="!validateForm" @click="loginAdmin">Login</vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loginStatus: false,
      showDismissibleAlert: false,
      message: "",
      passwordFieldType: "password",
      passwordShowIcon: "icon icon-eye-off",
      iconWrap: {
        marginTop: '-35px'
      },
      checkbox_remember_me: $cookies.get('rememberMe') || false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    ...mapActions("auth", ["loginJWT","getUserByRememberMeToken"]),
    loginAdmin() {

      const payload = {
        rememberMe: JSON.parse(this.checkbox_remember_me),
        email: this.user.email,
        password: this.user.password,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.loginJWT(payload)
            .then((response) => {
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
            });
        }
      });
    },

    switchPasswordVisibility(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon =
        this.passwordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
           }
      return true;
    }
  },
  created(){
    if(this.checkbox_remember_me){
      let rememberMeToken = $cookies.get('rememberMeToken')
      this.getUserByRememberMeToken({rememberMeToken}).then( res => {
        this.$router.push("/")
      })
    }
  }
};
</script>
